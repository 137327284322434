
export function useFormHelpers($v: any) {
  const { pushError } = useNotifications();

  const triggerFriendlyCaptcha = async function (val: any) {
    if (val.captcha == false) {
      try {
        await new Promise((resolve, reject) => {
          const cancelWatch = watch(() => val.captcha, () => {
            cancelWatch();
            resolve(true);
          });

          setTimeout(() => {
            cancelWatch();
            reject(false);
          }, 10000);
        })
      } catch (e) {
        pushError("Captcha konnte nicht verifiziert werden.");
        return false;
      }
    }

    return true;
  }

  const focusOnFirstInvalidField = function () {
    let invalidField = Object.keys($v.value).find(
      (field) => {
        return $v.value?.[field]?.$invalid === true;
      }
    );

    if (invalidField == 'billingAddress') {
      // Special handle register form
      invalidField = Object.keys($v.value.billingAddress).find(
        (field) => {
          return $v.value?.billingAddress?.[field]?.$invalid === true;
        }
      );
    }

    if (invalidField) {
      // Focus on the first invalid field
      const elem = document.querySelector('#' + invalidField);

      if (elem instanceof HTMLElement) {
        const htmlElem = <HTMLElement>elem as HTMLElement;

        htmlElem.focus();
        htmlElem.scrollTo();
      }
    }
  };

  return {
    focusOnFirstInvalidField,
    triggerFriendlyCaptcha,
  };
}